//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../MainComponents/Loader";

function AddEditLecture({ edit }) {
  const [values, setValues] = useState(null);
  const { lectureID, chapterID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditLecture": {
        return {
          ...state,
          submitAddEditLecture: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_LECTURE_API
    : process.env.REACT_APP_ADMIN_ADD_LECTURE_API;
  //!--------- add edit Lecture -------

  const [LectureAddEditSuccess, LectureAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditLecture.flag,
    state.submitAddEditLecture.dependency,
    state.submitAddEditLecture.data,
    true
  );

  //!--------- get the Lecture info for editing -------

  const [LectureInfo, LectureInfoLoading, LectureInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    lectureID
  );

  const [ChapterInfo, ChapterInfoErrors, ChapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_INFO_API}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );
  useEffect(() => {
    if (ChapterInfo) {
      let temp = ChapterInfo.data;
      setValues({
        edu_type: String(temp.edu_type),
        stage: String(temp.stage),
        section: temp.section,
      });
    }
  }, [ChapterInfo]);

  useEffect(() => {
    if (LectureInfo) {
      let temp = LectureInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        is_center: temp.is_center === 1 ? true : false,
        stage: String(temp.stage),
      });
    }
  }, [LectureInfo]);

  useEffect(() => {
    //!---add actions ----

    if (LectureAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [LectureAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({ mode: "onTouched", values });

  const watchType = watch("edu_type");
  const watchLevel = watch("stage");
  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          chapter_id: chapterID,
          img: data?.img[0] || null,
        }
      : lectureID
        ? {
            ...data,
            id: lectureID,
            img: data?.img[0] || null,
            visibility: data?.visibility === true ? 1 : 0,
            is_center: data?.is_center === true ? 1 : 0,
          }
        : {
            ...data,
            img: data?.img[0] || null,
          };
    dispatch({
      type: "setSubmitAddEditLecture",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditLecture.dependency,
        data: finalData,
      },
    });
  };

  const isChapter = ChapterInfo || LectureInfo?.data?.chapter_id ? true : false;

  if (LectureInfoLoading) {
    return <Loader />;
  }
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Lectures | PRODIGY"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {lectureID
            ? "Edit lecture data"
            : "Please fill in the information to add the lecture."}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="title">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="title"
                placeholder="Name"
                name="title"
                autoComplete="on"
                {...register("title", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.title && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.title.type === "required" &&
                    "Please fill out this field"}
                  {errors.title.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.title.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.title.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.title && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.title[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="Type"
              >
                Type
              </label>
              <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                {/** ig  */}
                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="ig"
                    value="0"
                    disabled={isChapter}
                    {...register("edu_type", {
                      required: isChapter ? false : true,
                    })}
                  />
                  <label className="cursor-pointer text-start" htmlFor="ig">
                    IG
                  </label>
                </div>

                {/** national */}
                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="national"
                    value="1"
                    disabled={isChapter}
                    {...register("edu_type", {
                      required: isChapter ? false : true,
                    })}
                  />
                  <label
                    className="cursor-pointer text-start"
                    htmlFor="national"
                  >
                    National
                  </label>
                </div>
              </div>
              {errors.edu_type && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.edu_type.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.edu_type && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.edu_type[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                LectureAddEditErrors?.response?.data?.errors &&
                  LectureAddEditErrors?.response?.data?.errors?.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data.errors.edu_type[0]}
                    </p>
                  )
              }
            </div>
            {!LectureInfoLoading && (
              <>
                {watchType !== null && (
                  <div
                    className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
                  >
                    <label className="w-full truncate" htmlFor="stage">
                      Classroom
                    </label>

                    <select
                      name="stage"
                      id="stage"
                      disabled={isChapter}
                      {...register("stage", { required: true })}
                    >
                      <option value="" disabled selected>
                        Select a stage
                      </option>
                      {watchType === "0" ? (
                        <>
                          <option value="9">Nine Year</option>
                          <option value="10">Ten Year</option>
                        </>
                      ) : (
                        <>
                          <option value="22">Second preparatory</option>
                          <option value="33">Third preparatory </option>
                          <option value="1">First Secondary</option>
                          <option value="2">Second Secondary</option>
                          <option value="3">Third Secondary</option>
                        </>
                      )}
                    </select>

                    {errors.stage && (
                      <p className="text-[12px] text-blue-900 ">
                        {errors.stage.type === "required" &&
                          "Please fill out this field"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      LectureAddEditErrors &&
                        LectureAddEditErrors?.response?.data?.errors?.stage && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {
                              LectureAddEditErrors?.response?.data?.errors
                                ?.stage[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}
              </>
            )}
          </div>
          {watchType === "1" && (
            <>
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                    {/** scientific  */}

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        disabled={isChapter}
                        {...register("section", {
                          required: isChapter ? false : true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    {/** arts  */}
                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        disabled={isChapter}
                        {...register("section", {
                          required: isChapter ? false : true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.section[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    LectureAddEditErrors?.response?.data?.errors &&
                      LectureAddEditErrors?.response?.data?.errors?.section && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            LectureAddEditErrors?.response?.data.errors
                              .section[0]
                          }
                        </p>
                      )
                  }
                </>
              ) : (
                ""
              )}
            </>
          )}
          {/** price views  days filed */}
          <div className="flex  w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" price flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="price">
                Lecture price
              </label>
              <input
                className="signin-inputs w-full "
                type="number"
                min={0}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.price && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.price.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.price && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>
            <div className=" flex  w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="views">
                Views
              </label>
              <input
                className="signin-inputs w-full "
                type="number"
                min={1}
                id="views"
                placeholder="000"
                name="views"
                autoComplete="on"
                {...register("views", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.views && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.views.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.views && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.views[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="flex  w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" days flex w-full flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="expired_at">
                Number of days
              </label>
              <input
                className="signin-inputs w-full "
                type="number"
                min={1}
                id="expired_at"
                placeholder="000"
                name="expired_at"
                autoComplete="on"
                {...register("expired_at", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.expired_at && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.expired_at.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.expired_at && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        LectureAddEditErrors?.response?.data?.errors
                          ?.expired_at[0]
                      }
                    </p>
                  )
              }
            </div>
          </div>
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-start gap-16 md:flex-col md:gap-10 ">
              <div className="visibility flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center gap-10">
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                  <label className="visibility-switch order-2">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className="visibility flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center gap-10">
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="is_center"
                    >
                      Center
                    </label>
                  </div>
                  <label className="visibility-switch order-2">
                    <input
                      className=""
                      id="is_center"
                      name="is_center"
                      {...register("is_center", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                </div>

                {errors.is_center && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.is_center.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors?.is_center && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.is_center[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}
          {/** Lecture Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload the lecture image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 50000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the lecture"}
                {errors.img.type === "validate" && "Maximum image size is 50MB"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {LectureAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>
          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      LectureAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}
          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditLecture.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditLecture.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{lectureID ? "Edit Lecture" : "Add Lecture"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditLecture;
