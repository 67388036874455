import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../../../../MainComponents";
function AddEditChapter({ edit }) {
  const [values, setValues] = useState(null);
  const { chapterID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditChapter": {
        return {
          ...state,
          submitAddEditChapter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditChapter: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_CHAPTER_API
    : process.env.REACT_APP_ADMIN_ADD_CHAPTER_API;
  //!--------- add edit Chapter -------

  const [ChapterAddEditSuccess, ChapterAddEditErrors, ChapterAddEditLoading] =
    useAxios(
      api,
      "POST",
      state.submitAddEditChapter.flag,
      state.submitAddEditChapter.dependency,
      state.submitAddEditChapter.data,
      true
    );

  //!--------- get the Chapter info for editing -------

  const [ChapterInfo, ChapterInfoErrors, ChapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_INFO_API}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );
  useEffect(() => {
    if (ChapterInfo) {
      let temp = ChapterInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        edu_type: String(temp.edu_type),
        stage: String(temp.stage),
      });
    }
  }, [ChapterInfo]);

  useEffect(() => {
    //!---add actions ----

    if (ChapterAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ChapterAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({ mode: "onTouched", values });

  const watchType = watch("edu_type");
  const watchLevel = watch("stage");

  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          id: chapterID,
          visibility: +data?.visibility,
          img: data?.img[0] || null,
        }
      : {
          ...data,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditChapter",
      payload: {
        flag: "AddEditChapter",
        dependency: !state.submitAddEditChapter.dependency,
        data: finalData,
      },
    });
  };

  // useEffect(() => {
  //   if (watchType) {
  //     setValue("stage", "");
  //   }
  // }, [watchType, setValue]);

  if (ChapterInfoLoading) return <Loader />;
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Chapters | PRODIGY"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {chapterID
            ? "Modify class information"
            : "Please fill in the information to add the chapter"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
        >
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="Type"
                >
                  Type
                </label>
                <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                  {/** ig  */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="ig"
                      value="0"
                      {...register("edu_type", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="ig">
                      IG
                    </label>
                  </div>

                  {/** national */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="national"
                      value="1"
                      {...register("edu_type", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="national"
                    >
                      National
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.edu_type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.edu_type[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  ChapterAddEditErrors?.response?.data?.errors &&
                    ChapterAddEditErrors?.response?.data?.errors?.edu_type && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          ChapterAddEditErrors?.response?.data.errors
                            .edu_type[0]
                        }
                      </p>
                    )
                }
              </div>
              {!ChapterInfoLoading && (
                <>
                  {watchType !== null && (
                    <div
                      className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
                    >
                      <label className="w-full truncate" htmlFor="stage">
                        Classroom
                      </label>

                      <select
                        name="stage"
                        id="stage"
                        {...register("stage", { required: true })}
                      >
                        <option value="" disabled selected>
                          Select a stage
                        </option>
                        {watchType === "0" ? (
                          <>
                            <option value="9">Nine Year</option>
                            <option value="10">Ten Year</option>
                          </>
                        ) : (
                          <>
                            <option value="22">Second preparatory</option>
                            <option value="33">Third preparatory </option>
                            <option value="1">First Secondary</option>
                            <option value="2">Second Secondary</option>
                            <option value="3">Third Secondary</option>
                          </>
                        )}
                      </select>

                      {errors.stage && (
                        <p className="text-[12px] text-blue-900 ">
                          {errors.stage.type === "required" &&
                            "Please fill out this field"}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        ChapterAddEditErrors &&
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.stage && (
                            <p className="w-full  text-[12px] text-blue-900  ">
                              {
                                ChapterAddEditErrors?.response?.data?.errors
                                  ?.stage[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {watchType === "1" && (
            <>
              {" "}
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                    {/** scientific  */}

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    {/** arts  */}
                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.section[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    ChapterAddEditErrors?.response?.data?.errors &&
                      ChapterAddEditErrors?.response?.data?.errors?.section && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            ChapterAddEditErrors?.response?.data.errors
                              .section[0]
                          }
                        </p>
                      )
                  }
                </>
              ) : (
                ""
              )}
            </>
          )}

          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-white ">
                    {errors.visibility.type === "required" &&
                      "برجاء ملئ هذا الحقل"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ChapterAddEditErrors &&
                    ChapterAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full text-end text-[12px] text-white  ">
                        {
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** course Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload Course image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 50000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the class"}
                {errors.img.type === "validate" && "Maximum image size is 50MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {ChapterAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>
          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      ChapterAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid ||
              (ChapterAddEditLoading && state.submitAddEditChapter.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {ChapterAddEditLoading && state.submitAddEditChapter.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {chapterID ? "Edit Chapter" : "Add chapter"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditChapter;
