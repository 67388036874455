import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditEssayQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID } = useParams();
  const [question, setQuestion] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [degree, setDegree] = useState("");
  const [errors, setErrors] = useState("");
  const [vdo_id, setVideoId] = useState("");
  const [platform, setPlatform] = useState("");

  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    }
  }, [question, difficulty, degree]);

  //!---add edit question request
  let api =
    type === "homework"
      ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_ESSAY_QUESTIONS_API
      : type === "quiz"
        ? process.env.REACT_APP_ADMIN_ADD_QUIZ_ESSAY_QUESTIONS_API
        : edit
          ? process.env.REACT_APP_EDIT_QUESTION_API
          : `${process.env.REACT_APP_ADD_ESSAY_QUESTION_API}${categoryID}`;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  //!---get question data request
  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;

  const [QuestionDataSuccess, QuestionErrors] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );
  useEffect(() => {
    if (QuestionDataSuccess) {
      setDifficulty(QuestionDataSuccess.data.difficulty);
      setQuestion(QuestionDataSuccess.data.question);
      setDegree(QuestionDataSuccess.data?.degree);
      setPlatform(QuestionDataSuccess?.data?.platform);
      setVideoId(QuestionDataSuccess?.data?.vdo_id);
    }
  }, [QuestionDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (question === "") {
      setErrors("question");
      toast.error("Please fill out all the required information");
    } else if (difficulty === "" && !type) {
      setErrors("difficulty");
      toast.error("Please fill out all the required information");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("Please enter a number greater than zero");
    } else {
      const data =
        type === "homework"
          ? {
              question: question,
              degree: degree,
              homework_id: id,
              vdo_id: vdo_id || null,
              platform: platform || null,
            }
          : type === "quiz"
            ? {
                question: question,
                degree: degree,
                quiz_id: id,
                vdo_id: vdo_id || null,
                platform: platform || null,
              }
            : {
                question: question,
                difficulty: difficulty,
                degree: degree,
                id: edit ? questionID : categoryID,
                vdo_id: vdo_id || null,
                platform: platform || null,
              };
      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setDifficulty("");
      setDegree("");
      setPlatform("");
      setVideoId("");
      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light">
      {!type && <HelmetTags title="Question Bank | PRODIGY"></HelmetTags>}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container my-20 flex flex-col items-center"
      >
        <h2 className="my-20 text-center text-3xl font-bold">
          {edit
            ? "Please fill in the information to modify the question."
            : "Please fill in the information to add the question."}
        </h2>
        {/**
         * //!------question-----
         */}
        <div className=" question mb-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">Question</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },

              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={question}
            onChange={(event, editor) => {
              const data = editor.getData();
              setQuestion(data);
            }}
          />
          {errors === "question" && (
            <p className="text-[12px] text-blue-900 ">
              Please fill out this field
            </p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="w-full text-[12px] text-blue-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------Difficulty degree -----
         */}
        <div className="flex w-full items-start gap-10 md:flex-col-reverse my-10">
          {!type && !lectureQuestion && (
            <div
              className={` flex w-1/2 flex-col justify-center gap-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="difficulty">
                Level
              </label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                name="difficulty"
                id="difficulty"
              >
                <option hidden disabled value="">
                  Level
                </option>
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>

              {errors === "difficulty" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                    <p className="w-full text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.difficulty[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
          <div className=" Degree flex w-1/2  flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              Degree
            </label>
            <input
              className="signin-inputs w-full "
              type="number"
              inputMode="numeric"
              min={1}
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              id="degree"
              placeholder="000"
              name="degree"
              autoComplete="on"
            />

            {errors === "degree" && (
              <p className="text-[12px] text-blue-900 ">
                Please fill out this field
              </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className="text-[12px] text-blue-900 ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>
        <div className="flex w-full items-start  gap-10 md:flex-col">
          <div className=" vdo_id flex w-1/2  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="vdo_id">
              Video Link{" "}
            </label>
            <input
              className="signin-inputs w-full pl-4    "
              type="text"
              id="vdo_id"
              placeholder="Video Link "
              value={vdo_id}
              onChange={(e) => setVideoId(e.target.value)}
              name="vdo_id"
              autoComplete="on"
            />

            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.vdo_id && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.vdo_id[0]}
                  </p>
                )
            }
          </div>

          <div
            className={`flex w-1/2  flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="platform">
              Platform
            </label>
            <select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              required={vdo_id}
              name="platform"
              id="platform"
            >
              <option hidden disabled value="">
                Platform
              </option>
              <option value="youtube">youtube</option>
              <option value="vimeo">vimeo</option>
              <option value="vdocipher">vdocipher</option>
            </select>

            {errors === "platform" ||
              (vdo_id && platform === "" && (
                <p className="text-xs text-red-500">This field is required. </p>
              ))}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.platform && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.platform[0]}
                  </p>
                )
            }
          </div>
        </div>

        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit question" : "Add question"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditEssayQuestion;
