import React, { useEffect } from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEye,
  faEyeSlash,
  faGear,
  faSpinner,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useReducer } from "react";

const AssistantsChangePassword = ({ edit }) => {
  const [hide, setHide] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { assistantID } = useParams("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [
    EditAssistantPasswordSuccess,
    EditAssistantPasswordErrors,
    EditAssistantPasswordLoading,
  ] = useAxios(
    process.env.REACT_APP_UPDATE_ASSISTANT_UPDATE_PASSWORD_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues({ id: assistantID, ...formData });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditAssistantPasswordSuccess) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantPasswordSuccess]);

  useEffect(() => {
    if (EditAssistantPasswordErrors) {
      toast.error(EditAssistantPasswordErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantPasswordErrors]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
    >
      <h2 className="-mb-3 flex w-full items-center justify-center text-[17px] font-bold">
        <span>Change password</span>
        <FontAwesomeIcon className="ml-2 w-8" icon={faGear} />
      </h2>

      {/**  passwords fields */}
      <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
        {/** Password  */}
        <div className="w-1/2 md:order-1 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 ">
            <label htmlFor="password">Password</label>
            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password"
                className="signin-inputs  w-full"
                type={`${hide ? "text" : "password"}`}
                placeholder="Password"
                name="password"
                autoComplete="on"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide((prev) => !prev)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="w-5 h-5" icon={faEye} />
                ) : (
                  <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                )}
              </div>
            </div>
          </div>
          {errors.password && (
            <p className="mt-2 text-sm text-blue-500">
              {errors.password.type === "required" &&
                "Please fill out this field"}
              {errors.password.type === "maxLength" && "Maximum 25 characters"}
              {errors.password.type === "minLength" && "At least 6 digits"}
            </p>
          )}

          {
            //!-------server errors -----

            EditAssistantPasswordErrors &&
              EditAssistantPasswordErrors?.response?.data?.errors?.password && (
                <p className="text-xs text-blue-500  ">
                  {
                    EditAssistantPasswordErrors?.response?.data?.errors
                      ?.password[0]
                  }
                </p>
              )
          }
        </div>
        {/** Confirm Password  */}
        <div className="w-1/2 md:order-2 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
            <label htmlFor="password_confirmation">Confirm password</label>

            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password_confirmation"
                className="signin-inputs  w-full"
                type={`${hide ? "text" : "password"}`}
                placeholder="Confirm password"
                name="password_confirmation"
                autoComplete="on"
                {...register("password_confirmation", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                  validate: (value) => value === getValues("password"),
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide((prev) => !prev)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-5 w-5" icon={faEye} />
                ) : (
                  <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                )}
              </div>
            </div>
          </div>
          {errors.password_confirmation && (
            <p className="mt-2 text-sm text-blue-500">
              {errors.password_confirmation.type === "required" &&
                "Please, fill out this field."}
              {errors.password_confirmation.type === "maxLength" &&
                "Maximum 25 characters"}
              {errors.password_confirmation.type === "minLength" &&
                "At least 6 digits"}
              {errors.password_confirmation.type === "validate" &&
                "Password does not match"}
            </p>
          )}

          {
            //!-------server errors -----

            EditAssistantPasswordErrors &&
              EditAssistantPasswordErrors?.response?.data?.errors
                ?.password_confirmation && (
                <p className="text-xs text-blue-500  ">
                  {
                    EditAssistantPasswordErrors?.response?.data?.errors
                      ?.password_confirmation[0]
                  }
                </p>
              )
          }
        </div>
      </div>

      <button className=" submit mt-6 w-full" type="submit">
        {EditAssistantPasswordLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Change Password"
        )}
      </button>
    </form>
  );
};

export default AssistantsChangePassword;
