import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

function AddSetsForm({ type, id, refetch, setRefetch }) {
  const { lectureID, examID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddSets": {
        return {
          ...state,
          submitAddSets: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddSets: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm({
    defaultValues: {
      sets: [{ category_id: "", easy: "", medium: "", hard: "" }],
    },
    mode: "onTouched",
  });
  const { fields, append, remove } = useFieldArray({
    name: "sets",
    control,
  });

  //!--------- question bank sets for the sets form

  const [AllCategoriesSuccess, AllCategoriesErrors, AllCategoriesLoading] =
    useAxios(process.env.REACT_APP_ADMIN_SETS_CATEGORIES_API, "GET", "GET");
  //!--------- add questions to  quiz / homework   -------

  let addSetsApi =
    type === "homework"
      ? process.env.REACT_APP_HOMEWORK_ADD_SETS_API
      : type === "exam"
        ? process.env.REACT_APP_EXAM_ADD_SETS_API
        : process.env.REACT_APP_QUIZ_ADD_SETS_API;

  const [AddSetsSuccess, AddSetsErrors, submitLoading] = useAxios(
    addSetsApi,
    "POST",
    state.submitAddSets.flag,
    state.submitAddSets.dependency,
    state.submitAddSets.data,
    true
  );
  useEffect(() => {
    //!---add actions ----
    if (AddSetsSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [AddSetsSuccess]);

  const onSubmitAddSets = (data) => {
    data.sets.map((set) => {
      if (+set.easy + +set.medium + +set.hard === 0) {
        toast.error("Please Add Sets");
        return false;
      } else {
        let finalData;
        if (type === "homework") {
          finalData = data?.sets?.map((set) => ({
            homework_id: id.toString(),
            lecture_id: lectureID,
            ...set,
          }));
        }

        if (type === "quiz") {
          finalData = data?.sets?.map((set) => ({
            quiz_id: id.toString(),
            lecture_id: lectureID,
            ...set,
          }));
        }

        if (type === "exam") {
          finalData = data?.sets?.map((set) => ({
            exam_id: examID,
            ...set,
          }));
        }
        dispatch({
          type: "setSubmitAddSets",
          payload: {
            flag: "Quiz",
            dependency: !state.submitAddSets.dependency,
            data: { sets: finalData },
          },
        });
        return data;
      }
    });
  };

  return (
    <div className="flex w-full justify-center  dark:bg-dark dark:text-light">
      {/**
       * //!--- add questions to the exam -------
       */}
      <form
        method="post"
        onSubmit={handleSubmit(onSubmitAddSets)}
        className="flex w-3/4  flex-col  items-end justify-start gap-10 rounded-3xl bg-white p-16 shadow-3xl alg:w-full"
      >
        {/** row (category) easy medium hard */}

        {fields.map((field, index) => {
          return (
            <div
              key={field.id}
              className="flex w-full items-start justify-between gap-16 md:flex-col md:items-center md:gap-10 "
            >
              {/**
               * //!-----------category
               */}
              <div
                className={`flex w-1/4 flex-col items-end justify-center gap-2  md:w-full `}
              >
                <label
                  className="w-full truncate"
                  htmlFor={`category_${index + 1}`}
                >
                  Category
                </label>
                <select
                  name={`category_${index + 1}`}
                  id={`category_${index + 1}`}
                  {...register(`sets.${index}.category_id`, {
                    required: { value: true, message: "category is required" },
                  })}
                >
                  {AllCategoriesSuccess?.data?.map((category_id) => (
                    <option key={category_id.key} value={category_id.key}>
                      {category_id.title}
                    </option>
                  ))}
                </select>
                {errors?.sets?.[index]?.category_id && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors?.sets?.[index]?.category_id.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddSetsErrors &&
                    AddSetsErrors?.response?.data?.errors?.sets?.[index]
                      ?.category_id && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          AddSetsErrors?.response?.data?.errors?.sets?.[index]
                            ?.category_id[0]
                        }
                      </p>
                    )
                }
              </div>
              {/**
               * //!-----------easy
               */}
              <div className=" easy flex w-1/4 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="easy">
                  Easy
                </label>
                <input
                  className="signin-inputs w-full "
                  type="number"
                  min={0}
                  id="easy"
                  placeholder="Easy"
                  name="easy"
                  autoComplete="on"
                  {...register(`sets.${index}.easy`, {
                    required: true,
                  })}
                />
                {errors?.sets?.[index]?.easy && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors?.sets?.[index]?.easy.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddSetsErrors &&
                    AddSetsErrors?.response?.data?.errors?.sets?.[index]
                      ?.easy && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          AddSetsErrors?.response?.data?.errors?.sets?.[index]
                            ?.easy[0]
                        }
                      </p>
                    )
                }
              </div>
              {/**
               * //!-----------medium
               */}
              <div className=" medium flex w-1/4 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="medium">
                  medium
                </label>
                <input
                  className="signin-inputs w-full "
                  type="number"
                  min={0}
                  id="medium"
                  placeholder="medium"
                  name="medium"
                  autoComplete="on"
                  {...register(`sets.${index}.medium`, {
                    required: true,
                  })}
                />
                {errors?.sets?.[index]?.medium && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors?.sets?.[index]?.medium.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddSetsErrors &&
                    AddSetsErrors?.response?.data?.errors?.sets?.[index]
                      ?.medium && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          AddSetsErrors?.response?.data?.errors?.sets?.[index]
                            ?.medium[0]
                        }
                      </p>
                    )
                }
              </div>
              {/**
               * //!-----------hard
               */}
              <div className=" hard flex w-1/4 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="hard">
                  difficult
                </label>
                <input
                  className="signin-inputs w-full "
                  type="number"
                  min={0}
                  id="hard"
                  placeholder="difficult"
                  name="hard"
                  autoComplete="on"
                  {...register(`sets.${index}.hard`, {
                    required: true,
                  })}
                />

                {errors?.sets?.[index]?.hard && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors?.sets?.[index]?.hard.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddSetsErrors &&
                    AddSetsErrors?.response?.data?.errors?.sets?.[index]
                      ?.hard && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          AddSetsErrors?.response?.data?.errors?.sets?.[index]
                            ?.hard[0]
                        }
                      </p>
                    )
                }
              </div>
              {/**
               * //!-----------delete
               */}
              <div className="delete-set my-auto">
                <button
                  onClick={() => remove(index)}
                  className={` bg-red-00 group mt-7 rounded-xl p-3 duration-300 hover:bg-secondary   active:scale-90 ${
                    index === 0 && "pointer-events-none opacity-0"
                  }`}
                >
                  <FontAwesomeIcon
                    className=" text-3xl duration-300 group-hover:text-light group-active:scale-90"
                    icon={faTrash}
                  />
                </button>
              </div>
            </div>
          );
        })}

        <button
          onClick={() =>
            append({ category_id: "", easy: "", medium: "", hard: "" })
          }
          className="ml-4 rounded-xl border-2 border-accent bg-accent px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-accent   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Category
        </button>
        {/** submit */}

        <button
          disabled={!isValid || (submitLoading && state.submitAddSets.flag)}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && state.submitAddSets.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Add questions"
          )}
        </button>
      </form>
    </div>
  );
}

export default AddSetsForm;
