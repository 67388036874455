import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NotFound() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(pathname.includes("/admin") ? "/admin/signin" : "/signin");
  }, [navigate, pathname]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      404 Page Not Found
    </div>
  );
}

export default NotFound;
